<template>
  <v-tooltip
    max-width="400"
    :left="dir('left')"
    :right="dir('right')"
    :bottom="dir('bottom')"
    :top="dir('top')">
    <template v-slot:activator="{ on }">
      <v-icon
        class="info-icon ml-2"
        size="18"
        color="primary"
        v-on="on">
        mdi-information
      </v-icon>
    </template>

    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        required: true
      },
      direction: {
        type: String,
        default: 'bottom'
      }
    },
    methods: {
      dir (direction) {
        return this.direction === direction
      }
    }
  }
</script>

